import React, {useState, useEffect, useRef} from "react";
import { useLocation } from 'react-router-dom';
import styles from './home.module.less'
import Vioce from "../components/voice/vioce";
import IotAiView from "../components/iotAiView/iotAiView";
import Loading from "../components/loading/loading";

import {ChatApi} from '../services/chatApi.js';
import {DeviceApi} from '../services/deviceApi.js';

export function Home() {
    // let webGlId = new URLSearchParams(useLocation().search).get('webGlId');
    let sn = new URLSearchParams(useLocation().search).get('sn');
    const [showLoading, setShowLoading] = useState(true);
    const [loadingBtnFlag, setLoadingBtnFlag] = useState(false);
    const [showBgMask, setShowBgMask] = useState(false);
    const [showVoice, setShowVoice] = useState(false);
    const [showAiView, setShowAiView] = useState(false);
    const [webGlId, setWebGlId] = useState("");
    const [indCode, setIndCode] = useState("");
    const [key, setKey] = useState("");
    const [projectId, setProjectId] = useState(null);
    const [positionId, setPositionId] = useState(null);
    const [employmentType, setEmploymentType] = useState(null);
    const [resumeId, setResumeId] = useState("");

    const voiceRef = useRef();

    const chatApi = new ChatApi();
    const deviceApi = new DeviceApi();

    useEffect(() => {
        //监听小程序回传的语音
        window.addEventListener('showBgColor', handleShowBgColor);

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(function(stream) {
                    console.log('You let me use your mic!')
                })
                .catch(function(err) {
                    console.log('No mic for you!')
                });
        }

        // 监听页面点击或触摸
        document.addEventListener('click', handleClick, false);
        document.addEventListener('touchstart', handleClick, false);

        // alert(sn)

        deviceApi.getDeviceConfig(sn).then(res => {
            if(res.data.code === 200){

                let data =res.data.data;
                setWebGlId(data.webglId);
                setIndCode(data.indCode);
                console.log(data);
                //把显示名称放到缓存里
                localStorage.setItem("WJ_DISPLAY_NAME", data.displayName);
                localStorage.setItem("IND_CODE", data.indCode);
                localStorage.setItem("IotLogo", data.iotLogo);
                localStorage.setItem("IotTitle", data.title);
                localStorage.setItem("MainUrl", data.mainUrl);
                localStorage.setItem("DefaultToMain", data.defaultToMain);
                localStorage.setItem("PSY_CON_KEY", data.psyConKey);
                setKey(data.key);
                if(data.broadcastConfigList && data.broadcastConfigList.length > 0){
                    data.broadcastConfigList.forEach(item => {
                        getBroadcast(item.broadcastKey, item.broadcastCode);
                    })
                }

                setShowVoice(true)
            }else{
                alert("加载设备信息出错，请联系官方客服")
            }
        })

        //
        // getBroadcast("positionSearch","4632aabe33f0493faf8a903f8deafb8b");
        // getBroadcast("policy","4aae5e9da2a24e3fb2a7319c3c81e3ff");
        // getBroadcast("positionDetail","30c560d6565c4d3096b7a6ea313fbd19");
        // getBroadcast("verify","6a1c7f0898e24af087bc730b3cf80724");
        // getBroadcast("succeed","96d73ef31cba41a78f90c8fdba6184e0");
        // getBroadcast("defeat","18b6b6e3bd2b44668bc2fb47a5c90022");
        // getBroadcast("continueVerify","275ad357b86b431493d93604ea6d083b");
        // getBroadcast("swipeID ","275ad357b86b431493d93604ea6d083b");
        // getBroadcast("swipeIDDefeat ","ba05d7024c0e410b9efe823c1efa53b8");



        return () => {
            document.removeEventListener('click', handleClick, false);
            document.removeEventListener('touchstart', handleClick, false);
        }

    }, []);

    const handleClick = (event) => {
        // console.log('You clicked the page!', event);

        let e = new CustomEvent('refreshTime', { detail:"refreshTime" });
        window.dispatchEvent(e);
    }

    const getBroadcast = async (key, dataId) => {
        await chatApi.getBroadcast(dataId).then(res => {

            if(res.data && res.data.voiceBroadcastResult){
                let voiceData = window.Lingjing.Tools.storyFormat(res.data.voiceBroadcastResult)
                localStorage.setItem(key, JSON.stringify(voiceData));
            }
        })
    }

    const handleShowBgColor = (data) => {
        // alert(JSON.stringify(data.detail))
        setShowBgMask(data.detail.data)
    }

    // 点击 开始探索 回调
    function handleCloseLoading() {
        console.log("loading close");
        setShowLoading(false);
    }

    const toAiView = (positionId,employmentType,projectId,resumeId) => {

        setPositionId(positionId)
        setEmploymentType(employmentType)
        setProjectId(projectId)
        setResumeId(resumeId)

        setShowAiView(true)
    }

    const toVoice = () => {
        console.log("home ------ toVoice")
        setShowAiView(false)
        // 调用子组件的方法
        if(voiceRef.current){
            voiceRef.current.showVoiceCom();
        }

    }

    return(
        <div style={{width:"100vw",height:"100vh"}}>

            <Vioce ref={voiceRef} webGlId={webGlId} indCode={indCode} difyKey={key} sn={sn} callParentMethod={toAiView} />

            {
                showAiView?(
                    <IotAiView indCode={indCode} sn={sn} projectId={projectId} positionId={positionId} employmentType={employmentType} resumeId={resumeId} callParentMethod={toVoice}></IotAiView>
                ):('')
            }


            {
                showBgMask ? (
                    <div className={styles.mask}></div>
                ):('')
            } 
        </div>
    )
}